@import 'Styles/includes';

.PasswordProtected {
    $root: &;

    &__Content {
        p {
            @include textstyle(body-regular);

            margin: 24px 0;
        }

        form {
            font-family: $base-font;
            max-width: map-get($maxwidths, content);

            p {
                display: flex;
                justify-content: flex-start;
                align-items: flex-end;
            }

            label {
                @extend %form__label;

                flex: 1 0 auto;
                margin: 0 !important;
            }

            input {
                &:not([type='submit']) {
                    @extend %form__input;

                    margin-top: 8px;
                }

                &[type='submit'] {
                    @extend %form__submit;

                    flex: 0 1 auto;
                    text-align: center;
                    max-width: 150px;
                    margin-left: 8px !important;
                }
            }
        }
    }
}
