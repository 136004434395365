@import 'Styles/includes';

.Usp {
    $root: &;

    &__Block {
        border-radius: 4px;
        background-color: $red-5;
        box-shadow: 2px 16px 32px 0 rgba(black, .12);

        #{$root}--HasFactbox & {
            margin-bottom: 32px;

            @include media(L) {
                margin-bottom: 40px;
            }
        }
    }

    &__ImageContainer {
        position: relative;
        overflow: hidden;
    }

    &__Image {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        overflow: hidden;

        &::after {
            content: '';
            display: block;
            height: 0;
            padding-bottom: #{percentage(216 / 384)};
        }
    }

    &__Content {
        padding: 20px 20px 24px;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        background: white;

        @include media(M) {
            padding: 24px 24px 32px;
        }

        #{$root}--IsFactbox & {
            border-radius: 0;
        }
    }

    &__Link {
        display: block;
        padding: 18px 20px;
        font-size: 1.6rem;
        font-weight: $bold;
        line-height: normal;

        @include media(M) {
            padding: 22px 24px;
        }

        &::after {
            content: '';
            display: inline-block;
            width: 20px;
            height: 14px;
            margin-left: 11px;
            background: url('#{$assetsPath}/img/arrow-large--grey-90.svg') no-repeat center center;
            background-size: contain;
            transition: transform $transition;
        }

        &:hover {
            &::after {
                transform: translateX(5px);
            }
        }
    }

    &__Header {
        display: flex;
        align-items: center;
        margin-bottom: 16px;
    }

    &__Title {
        @include textstyle(h3);

        @include media(M) {
            font-size: 3.6rem;
        }
    }

    &__Meta {
        display: flex;
        flex-wrap: wrap;
        margin-left: 12px;
        font-size: 1.4rem;
    }

    &__Price {
        padding: 4px 8px;
        margin-right: 8px;
        color: $blue;
        font-weight: bold;
        white-space: nowrap;
        border-radius: 2px;
        background-color: $blue-10;
    }

    &__PaymentType {
        padding: 4px 0;
    }

    &__Text {
        * {
            font-size: 1.6rem;
            line-height: 1.5;
            margin-bottom: 8px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        p {
            font-weight: normal;
        }

        a {
            color: $base-color;
            text-decoration: underline;
            transition: color $transition;

            &:hover {
                color: $red;
            }
        }

        b,
        strong {
            font-weight: $bold;
        }

        i,
        em {
            font-style: italic;
        }

        li {
            position: relative;
            padding-left: 24px;

            &::before {
                content: '';
                display: block;
                position: absolute;
                top: 3px;
                left: 0;
                width: 17px;
                height: 17px;
                background: url('#{$assetsPath}/img/check--grey-90.svg') no-repeat center center;
                background-size: contain;
            }
        }

        h2,
        h3,
        h4,
        h5,
        h6 {
            @include textstyle(h6);

            margin-bottom: 8px;
            font-size: 1.6rem !important;
        }
    }

    &__FactBox {
        padding: 20px 20px 24px;
        border-radius: 4px;
        background-color: $red-5;

        @include media(M) {
            padding: 24px 24px 32px;
        }

        h2,
        h3,
        h4,
        h5,
        h6 {
            margin-bottom: 12px;
            font-size: 2rem !important;
        }
    }
}
