@import 'Styles/includes';

.MyPagesInfo {
    $root: &;

    position: relative;

    &__Title {
        @include textstyle(h4);
    }

    &__Link {
        color: $link-color;
        text-decoration: underline;
        transition: color $transition;

        @include media(SL) {
            position: absolute;
            top: 5px;
            right: 0;
        }

        @include media(M) {
            top: 10px;
        }

        &:hover {
            color: $link-hover-color;
            text-decoration: none;
        }

        &::after {
            content: '';
            display: inline-block;
            position: relative;
            top: 1px;
            width: 15px;
            height: 15px;
            margin-left: 5px;
            background: url('#{$assetsPath}/img/external--red.svg') no-repeat center center;
            background-size: contain;
        }
    }

    &__List {
        display: flex;
        flex-wrap: wrap;
        margin: 8px 0 24px -16px;
    }

    &__Item {
        @include textstyle(body-small);

        flex: 0 0 auto;
        margin: 8px 0 8px 16px;
        width: calc(100% - 16px);

        @include media(SL) {
            width: calc(50% - 16px);
        }

        @include media(M) {
            width: calc(#{percentage(1/3)} - 16px);
        }
    }

    &__Label {
        font-weight: $bold;
    }

    &__Value {
        white-space: pre-wrap;

        #{$root}__Item--Email & {
            word-break: break-word;
        }
    }
}
