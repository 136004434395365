@import 'Styles/includes';

.DonationForm {
    $root: &;

    &__Wrap {
        @include wrap;

        margin-top: 48px;
        margin-bottom: 48px;
        display: flex;
        flex-direction: column;

        @include media(M) {
            margin-top: 64px;
            margin-bottom: 64px;
        }

        @include media(ML) {
            flex-direction: row;
        }

        @include media(XL) {
            margin-bottom: 80px;
        }
    }

    &__Form {
        flex: 1 1 auto;
        width: 100%;
        color: $grey-80;

        @include media(ML) {
            width: auto;
        }
    }

    &__Sidebar {
        flex: 0 0 auto;
        order: -1;
        width: 100%;
        margin-bottom: 32px;

        @include media(ML) {
            order: 0;
            width: 360px;
            margin-bottom: 0;
            margin-left: 32px;
        }

        @include media(L) {
            width: 384px;
            margin-left: 40px;
        }

        @include media(XL) {
            margin-left: 64px;
        }
    }

    &__Usp {
        margin-bottom: 24px;

        &:last-child {
            margin-bottom: 0 !important;
        }

        @include media(L) {
            margin-bottom: 32px;
        }
    }
}
