@import 'Styles/includes';

.Page {
    $root: &;

    &__Wrap {
        @include wrap;

        margin-bottom: 40px;

        @include media(ML) {
            margin-bottom: 60px;
        }

        @include media(L) {
            margin-bottom: 80px;
        }
    }

    &__Container {
        display: flex;
        flex-direction: column;
        max-width: map-get($maxwidths, article);
        margin: 0 auto;

        @include media(ML) {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
        }

        #{$root}--NoSidebar & {
            justify-content: center;
        }
    }

    &__Figure {
        position: relative;
        width: 100%;
        margin-top: 40px;

        @include media(M) {
            margin-top: 56px;
        }
    }

    &__FeaturedImage {
        width: 100%;
        margin-top: 40px;

        @include media(M) {
            margin-top: 56px;
        }
    }

    &__Title {
        @include textstyle(h1);

        order: 1;
        width: 100%;
        margin-top: 16px;
        margin-bottom: 16px;

        @include media(ML) {
            order: 0;
            margin-top: 40px;
            margin-bottom: 20px;
            max-width: map-get($maxwidths, content);
        }

        @include media(L) {
            margin-top: 72px;
        }
    }

    &__Content {
        flex: 1 1 auto;
        order: 1;

        @include media(ML) {
            order: 0;
            width: calc(100% - 32px - 339px);
            max-width: map-get($maxwidths, content);
            margin-right: 32px;
        }

        #{$root}--NoSidebar & {
            @include media(ML) {
                margin-right: 0;
            }
        }
    }

    &__Sidebar {
        flex: 0 0 auto;
        width: 100%;
        margin-top: 24px;

        @include media(ML) {
            width: 339px;
            margin-top: 0;
            margin-left: auto;
        }
    }

    &__Documents {
        margin: 20px 0;

        @include media(M) {
            margin: 40px 0;
        }
    }

    &__Share {
        width: 100%;
    }
}
