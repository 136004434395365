@import 'Styles/includes';

.HearingCarePost {
    $root: &;

    &__Wrap {
        @include wrap;

        margin-top: 40px;
        margin-bottom: 48px;

        @include media(M) {
            margin-top: 56px;
            margin-bottom: 80px;
        }
    }

    &__FeaturedImage {
        margin-top: 40px;

        @include media(M) {
            margin-top: 56px;
        }
    }

    &__Content {
        max-width: map-get($maxwidths, content);
        margin: 40px auto 0;

        @include media(M) {
            margin-top: 56px;
        }
    }

    &__Share {
        margin: 40px 0 0 0;

        @include media(M) {
            margin-top: 56px;
        }
    }
}
