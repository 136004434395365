@import 'Styles/includes';

.FactPost {
    $root: &;

    &__Wrap {
        @include wrap;

        margin-top: 72px;
        margin-bottom: 40px;

        @include media(M) {
            margin-bottom: 80px;
        }
    }

    &__ButtonContainer {
        position: relative;
        top: -72px;
    }

    &__Button {
        position: absolute !important;
        top: 16px;
        right: 0;
    }

    &__Header {
        max-width: map-get($maxwidths, content);
        margin: 0 auto;
    }

    &__Title {
        @include textstyle(h1);
    }

    &__FeaturedImage {
        margin: 40px auto 0;

        @include media(M) {
            margin-top: 56px;
        }
    }

    &__Preamble {
        margin-top: 16px;
    }

    &__Content {
        max-width: map-get($maxwidths, content);
        margin: 40px auto 0;

        @include media(M) {
            margin-top: 56px;
        }
    }

    &__Share {
        margin: 40px 0 0 0;

        @include media(M) {
            margin-top: 56px;
        }
    }
}
