@import 'Styles/includes';

.News {
    $root: &;

    &__Wrap {
        @include wrap;

        margin-top: 40px;
        margin-bottom: 40px;

        @include media(M) {
            margin-top: 64px;
            margin-bottom: 80px;
        }
    }

    &__Header {
        max-width: map-get($maxwidths, content);
        margin: 0 auto;
    }

    &__Meta {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 8px;
        font-size: 1.2rem;
        line-height: 1.33;
    }

    &__Term {
        color: $red;
        font-weight: $bold;
        text-transform: uppercase;

        &::after {
            content: '';
            display: inline-block;
            margin: -3px 9px;
            width: 1px;
            height: 15px;
            background: $grey-90;
        }
    }

    &__Title {
        @include textstyle(h1);
    }

    &__FeaturedImage {
        margin-top: 40px;

        @include media(M) {
            margin-top: 56px;
        }
    }

    &__Content {
        max-width: map-get($maxwidths, content);
        margin: 40px auto 0;

        @include media(M) {
            margin-top: 56px;
        }
    }

    &__Share {
        margin: 40px 0 0 0;

        @include media(M) {
            margin-top: 56px;
        }
    }
}
