@import 'Styles/includes';

.VideoContainer {
    $root: &;

    position: relative;

    &__Shell {
        @extend %shell--before;
        @extend %shell--after;

        // Append root class to make sure it overrides default
        #{$root} &::before {
            top: -1458px;
            left: -1992px;
            width: 2844px;
            height: 2018px;

        }

        #{$root} &::after {
            bottom: -1433px;
            right: -1824px;
            width: 2844px;
            height: 2018px;
            transform: rotate(180deg);
        }
    }

    &__Header {
        position: relative;
        padding-top: 48px;
        padding-bottom: 48px;
        background: $red-5;

        @include media(M) {
            padding-top: 64px;
            padding-bottom: 64px;
        }

        @include media(L) {
            padding-top: 80px;
            padding-bottom: 80px;
        }
    }

    &__Wrap {
        @include wrap;

        position: relative;
    }

    &__Player {
        flex: 0 0 auto;
        position: relative;
        width: 100%;
        max-width: map-get($maxwidths, article);
        margin: 0 auto 24px;
        box-shadow: 2px 16px 32px 0 rgba(black, .12);

        @include media(M) {
            margin-bottom: 40px;
        }

        &::before {
            content: '';
            display: block;
            width: 100%;
            height: 0;
            padding-bottom: #{percentage(9/16)};
        }
    }

    &__Container {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        max-width: map-get($maxwidths, article);
        margin: 0 auto;

        @include media(ML) {
            flex-direction: row;
        }
    }

    &__Content {
        flex: 1 1 auto;
        max-width: map-get($maxwidths, content);
    }

    &__Term {
        @include textstyle(body-small);

        margin-bottom: 12px;
        color: $red;
        font-weight: bold;
        text-transform: uppercase;
    }

    &__Title {
        @include textstyle(h2);

        margin-bottom: 12px;
    }

    &__Button {
        #{$root} & {
            margin-top: 32px;
        }
    }

    &__Copy {
        order: -1;
        flex: 0 1 auto;
        justify-self: flex-end;
        position: relative;

        @include media(ML) {
            order: 0;
        }

        #{$root} & {
            margin-bottom: 16px;
            margin-left: auto;

            @include media(ML) {
                margin-top: 24px;
                margin-bottom: 0;
            }
        }
    }

    &__VideoList {
        @include wrap;

        margin-top: 48px;
        margin-bottom: 48px;

        @include media(M) {
            margin-top: 64px;
            margin-bottom: 64px;
        }

        @include media(ML) {
            margin-top: 80px;
            margin-bottom: 80px;
        }
    }

    &__Subtitle {
        @include textstyle(h5);

        margin-bottom: 24px;

        @include media(L) {
            margin-bottom: 32px;
        }
    }
}
