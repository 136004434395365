@import 'Styles/includes';

.Calendar {
    $root: &;

    &__Wrap {
        @include wrap;

        margin-top: 40px;
        margin-bottom: 40px;

        @include media(M) {
            margin-top: 64px;
            margin-bottom: 80px;
        }
    }

    &__Header {
        max-width: map-get($maxwidths, content);
        margin: 0 auto;
    }

    &__Meta {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 8px;
        font-size: 1.2rem;
        line-height: 1.33;
    }

    &__Place {
        color: $red;
        font-weight: $bold;
        text-transform: uppercase;
    }

    &__Title {
        @include textstyle(h1);
    }

    &__FeaturedImage {
        margin-top: 40px;

        @include media(M) {
            margin-top: 56px;
        }
    }

    &__Info {
        padding: 24px;
        margin-bottom: 24px;
        border-radius: 4px;
        background: $red-5;

        @include media(M) {
            padding: 32px;
            margin-bottom: 40px;
        }
    }

    &__InfoRow {
        display: flex;

        &:not(:first-child) {
            margin-top: 8px;
        }
    }

    &__InfoLabel {
        font-weight: $bold;
    }

    &__InfoValue {
        margin-left: 8px;

        a {
            color: $red;
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }
        }
    }

    &__Content {
        max-width: map-get($maxwidths, content);
        margin: 40px auto 0;

        @include media(M) {
            margin-top: 56px;
        }
    }

    &__Share {
        margin: 40px 0 0 0;

        @include media(M) {
            margin-top: 56px;
        }
    }
}
