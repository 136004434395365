@import 'Styles/includes';

.Woocommerce {
    $root: &;

    &__Wrap {
        @include wrap;
    }

    &__Header {
        #{$root}--Create &,
        #{$root}--Login &,
        #{$root}--Page & {
            max-width: map-get($maxwidths, content);
            margin-left: auto;
            margin-right: auto;
        }
    }

    &__Title {
        @include textstyle(h1);

        margin: 24px 0;
    }

    &__Description {
        :global {
            @extend %richtext;
        }

        @include textstyle(body-regular);

        margin: 24px 0 40px;
        max-width: 600px;
    }

    &__Content {
        position: relative;

        #{$root}--Create &,
        #{$root}--Login &,
        #{$root}--Page & {
            max-width: map-get($maxwidths, content);
            margin-left: auto;
            margin-right: auto;
        }
    }

    &__Woocommerce {
        :global {
            @extend %woocommerce;
        }

        #{$root}--Account & {
            :global(.woocommerce) {
                display: flex;
                flex-wrap: wrap;
                flex-direction: column;

                @include media(M) {
                    flex-direction: row;
                }
            }
        }

        margin: 40px 0;

        @include media(M) {
            margin: 64px 0;
        }

        #{$root}--Error & {
            margin-top: 24px;

            @include media(M) {
                margin-top: 32px;
            }
        }
    }

    &__Pagination {
        margin: 40px 0;
    }
}
