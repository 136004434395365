%woocommerce {
    input[type=text],
    input[type=email],
    input[type=number],
    input[type=tel],
    input[type=password],
    select,
    textarea {
        @extend %form__input;

        + span {
            @extend %form__description;
        }
    }

    select {
        @extend %form__select;
    }

    input[type=submit] {
        -webkit-appearance: none;
        border-radius: 0;
    }

    label {
        @extend %form__label;
    }

    textarea {
        @extend %form__textarea;
    }

    p {
        @include textstyle(body-small);

        color: $grey-80;
        margin: 8px 0;

        a {
            color: inherit;
            font-size: inherit;
            text-decoration: underline;
            transition: color $transition;

            &:hover {
                color: $red;
            }
        }

        strong,
        b {
            font-weight: $bold;
        }

        i,
        em {
            font-style: italic;
        }
    }

    a {
        font-size: 1.6rem;
    }

    .woocommerce-register-intro {
        max-width: map-get($maxwidths, content);
        margin-bottom: 16px;
    }

    // For some wysiwyg styling
    .woocommerce-Tabs-panel--description,
    .woocommerce-register-intro {
        p,
        ul,
        ol {
            @include textstyle(body-small);

            margin: 8px 0;

            &:first-child {
                margin-top: 0;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }

        blockquote,
        h2,
        h3,
        h4,
        h5,
        h6 {
            margin: 16px 0;

            &:first-child {
                margin-top: 0;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }

        // TODO: Add bold and italic, and any other styles used in wysiwyg

        a {
            color: $link-color !important;
            text-decoration: underline;
            transition: color $transition;

            &:hover {
                color: $link-hover-color;
                text-decoration: none;
            }

            @include href-external {
                &::after {
                    content: '';
                    display: inline-block;
                    position: relative;
                    top: 1px;
                    width: 15px;
                    height: 15px;
                    margin-left: 5px;
                    background: url('#{$assetsPath}/img/external--red.svg') no-repeat center center;
                    background-size: contain;
                }
            }

            @include href-download {
                &::after {
                    content: '';
                    display: inline-block;
                    position: relative;
                    top: 1px;
                    width: 15px;
                    height: 15px;
                    margin-left: 5px;
                    background: url('#{$assetsPath}/img/download--red.svg') no-repeat center center !important;
                    background-size: contain;
                }
            }

            @media print {
                &::after {
                    content: " [" attr(href) "] " !important;
                    position: relative !important;
                    display: inline !important;
                    top: auto !important;
                    bottom: auto !important;
                    left: auto !important;
                    right: auto !important;
                    color: $base-color !important;
                    background: none !important;
                    opacity: 1 !important;
                }
            }
        }
    }

    .navbar__link {
        font-size: 2.6rem;
    }

    h2 {
        @include textstyle(h3);

        margin-bottom: 16px;
    }

    h3 {
        @include textstyle(h4);

        margin-bottom: 8px;
    }

    fieldset {
        margin-top: 24px;

        > legend {
            @include textstyle(h4);

            margin-bottom: 8px;
        }
    }

    mark {
        background-color: $red-5;
    }

    img {
        margin: 0;
        display: inline;
    }

    // For compensation for go back button
    div.product div.woocommerce-product-gallery {
        padding-top: 68px;

        @include media(M) {
            padding-top: 0;
        }
    }

    div.product div.summary {
        padding-top: 24px;

        @include media(M) {
            padding-top: 68px;
        }
    }

    td.product-name dl.variation dd {
        padding: 0;
        margin: 0;
    }

    table.shop_table {
        &.shop_table_responsive {
            thead {
                display: table-header-group;
            }

            tbody {
                &:first-child {
                    tr:first-child {
                        th,
                        td {
                            border-top: 0 !important;
                        }
                    }
                }
            }

            tr {
                display: table-row;
            }

            th,
            td {
                display: table-cell;
                background: transparent;

                &::before {
                    @extend %sr-only;
                }
            }

            td {
                border-top: 1px solid rgba(black, .1) !important;

                &.product-name {
                    text-align: left !important;
                }

                &.actions {
                    padding: 10px;
                }
            }
        }
    }

    .related.products {
        clear: both;
    }

    .page-title {
        margin-bottom: 30px;
    }

    .article {
        padding-top: 30px;
    }

    .shop-list {
        padding-bottom: 20px;
        position: relative;
    }

    .woocommerce-ordering {
        display: none;
        float: none;
    }

    .orderby {
        width: 100%;
    }

    .woocommerce-result-count {
        float: none;
    }

    .product_title {
        @include textstyle(h2);
    }

    .quantity .qty {
        width: 100px;
    }

    a.button,
    input.button,
    input.button.alt,
    button.button,
    button.button.alt,
    .wc-proceed-to-checkout a.checkout-button,
    .button.single_add_to_cart_button.alt,
    ul.products li.product .button {
        @extend %button--primary;

        background-color: $red !important;
        transition: all $transition;

        &:hover {
            color: white;
        }
    }

    .wc-back-button {
        @extend %button--secondary;

        position: absolute;
        top: 0;
        right: 0;
        z-index: 1;
    }

    a.button.add_to_cart_button {
        display: flex !important;
        justify-content: center;
        align-items: center;
    }

    .button.product_type_variable,
    .button.product_type_simple {
        text-align: center;
        width: 100%;
    }

    table.cart td.actions input.button {
        float: right;
        width: 100% !important;
        margin-bottom: 10px;
    }

    a.button.back-to-shop {
        font-size: 1.25em;
        margin-bottom: 10px;
        z-index: 1;

        &--single {
            display: none;
            font-size: 1.6rem;
            margin-bottom: 10px;
        }

        &--single-mobile {
            font-size: 1.6rem;
            color: white;
            margin-bottom: 20px;
        }
    }

    .woocommerce-result-count {
        margin-bottom: 0;
        order: 2;
    }

    .to-checkout-container {
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;

        .wpmenucart-contents {
            color: transparent;

            &::after {
                content: 'Fortsätt till kassan';
                border: none;
                border-radius: 0;
                background-color: $blue;
                color: white;
                display: block;
                font-weight: 500;
                transition: all 0.12s;
                height: 40px;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            span,
            i {
                display: none !important;
            }

        }
    }

    .woocommerce-thankyou-order-received {
        margin-bottom: 10px;
    }

    .to-checkout {
        order: 1;
        width: 100%;
        margin-bottom: 10px;
    }

    .button.single_add_to_cart_button.alt[disabled]:disabled {
        background-color: lighten($blue, 20%);

        &:hover {
            background-color: lighten($blue, 20%);
        }
    }

    .variations td {
        padding-bottom: 5px;
        padding-top: 5px;
    }

    .woocommerce-thankyou-order-received {
        font-size: 3rem;
        margin-bottom: 20px;
    }

    .woocommerce-thankyou-order-details {
        padding-left: 0;
    }

    #shipping_country_field,
    #billing_country_field {
        display: none;
    }

    div.product div.images {
        margin-bottom: 0;
    }

    .thumbnails {
        display: none;
    }

    .page-description {
        margin-bottom: 40px;
    }

    .attachment-shop_catalog,
    .attachment-shop_thumbnail,
    .attachment-shop_single {
        border: 1px solid $grey-60;
    }

    .product a {
        color: $base-color;
    }

    .product .wc-tab a {
        color: $blue;
    }

    ul.products li.product h3 {
        font-size: 1.8rem;
    }

    ul.products li.product .price {
        color: $grey-70;
    }

    .cart-empty {
        margin-bottom: 30px;
        max-width: map-get($maxwidths, content);
    }

    .cart-collaterals .cart_totals {
        width: auto;
        float: none;
    }

    div.product .woocommerce-tabs ul.tabs {
        border-left: 1px solid $grey-60;
        margin-bottom: 0;
        padding: 0;

        &::before {
            border-color: $grey-60;
        }
    }

    div.product .woocommerce-tabs ul.tabs li {
        border-radius: 0;
        border-color: $grey-60;

        &::before,
        &::after {
            border-color: $grey-60;
        }
    }

    div.product .woocommerce-tabs .panel {
        border-left: 1px solid $grey-60;
        border-right: 1px solid $grey-60;
        border-bottom: 1px solid $grey-60;
        padding: 20px;
    }

    ul.products {
        display: flex;
        flex-wrap: wrap;
        margin-left: -16px !important;
        margin-bottom: -16px !important;

        &::before,
        &::after{
            display: none;
        }

        @include media(ML) {
            margin-left: -38px !important;
            margin-bottom: -38px !important;
        }
    }

    ul.products li.product,
    .woocommerce-page ul.products li.product {
        flex: 0 0 auto;
        margin: 0 0 16px 16px !important;
        width: calc(50% - 16px) !important;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @include media(ML) {
            margin: 0 0 38px 38px !important;
            width: calc(25% - 38px) !important;
        }

        a {
            flex: 0 0 auto;
        }

    }

    .woocommerce-thankyou-order-details {
        font-size: 2rem;
        max-width: 100%;
    }

    .woocommerce-thankyou-order-failed-actions {
        margin-bottom: 32px;
    }

    .variations_button {
        margin-top: 30px;
    }

    div.product p.price {
        font-size: 2.4rem;
        color: $grey-80;
        margin-top: 8px;
        margin-bottom: 24px;
    }

    .product_meta {
        color: $grey-80;
    }

    .product span.onsale {
        width: 46px;
        height: 46px;
        line-height: 46px;
        padding: 0;
        color: black;
        background: $yellow;
    }

    .order_details li {
        float: none;
        margin-right: 0;
        padding-right: 0;
        border-right: none;
    }

    a.remove {
        width: 24px;
        height: 24px;
        color: $red !important;
        font-size: 2.3rem;

        &:hover {
            color: white !important;
            text-decoration: none;
            background-color: $red;
        }
    }

    .woocommerce-shipping-calculator {
        display: none;
    }

    div.product div.summary .cart {
        margin-top: 20px;
    }

    .woocommerce-breadcrumb {
        @include wrap;
    }

    .woocommerce-checkout {
        .col2-set {
            display: flex;
            flex-direction: column;

            .col-1,
            .col-2 {
                width: 100%;
                margin-bottom: 24px;

                @include media(M) {
                    margin-bottom: 40px;
                }
            }
        }

        #payment.woocommerce-checkout-payment {
            border-radius: 4px;
            background: $grey-10;

            .wc_payment_method {
                label {
                    display: inline-block;

                    img {
                        width: 60px;
                    }
                }

                &.payment_method_NetaxeptCw_Visa {
                    label img {
                        width: 90px;
                    }
                }

                .payment_box {
                    color: $base-color;
                    border-radius: 4px;
                    background: $grey-30;

                    &::before {
                        border-bottom-color: $grey-30;
                    }
                }
            }

            .woocommerce-terms-and-conditions-checkbox-text {
                color: $base-color;

                a {
                    font-size: inherit;
                    color: inherit;
                    text-decoration: underline;
                    transition: color $transition;

                    &:hover {
                        color: $red;
                    }
                }
            }
        }
    }

    @include media(M) {
        input[type=text],
        input[type=email],
        input[type=number],
        input[type=tel],
        input[type=password],
        select {
            height: 50px;
        }

        input.button,
        input.button.alt,
        .wc-proceed-to-checkout a.checkout-button,
        .button.single_add_to_cart_button.alt {
            height: 50px;
        }

        div.product div.summary {
            padding-top: 78px;
        }

        .woocommerce-breadcrumb {
            padding-top: 18px;
            padding-bottom: 20px;
        }

        .shop-list {
            padding-bottom: 40px;
        }

        ul.products li.product h3 {
            font-size: 2.4rem;
        }

        table.cart td.actions input.button {
            margin-bottom: 0;
        }
    }


    @include media(ML) {
        .woocommerce-breadcrumb {
            line-height: 2rem;
            font-size: 1.8rem;

            a {
                line-height: 2rem;
                font-size: 1.8rem;

                &:hover {
                    text-decoration: underline;
                }
            }
        }

        .woocommerce-ordering {
            margin-top: 10px;
            position: absolute;
            right: 5px;
            top: 0;
        }

        .orderby {
            width: auto;
        }

        .button.product_type_variable,
        .button.product_type_simple {
            text-align: left;
            width: auto;
        }

        a.button.back-to-shop {
            height: 50px;

            &--single {
                display: flex;
                float: right;
            }

            &--single-mobile {
                display: none;
            }
        }

        .to-checkout-container {
            display: none;
        }

        .woocommerce-result-count {
            display: none;
            order: 1;
        }

        .to-checkout {
            margin-bottom: 0;
            order: 2;
            width: auto;
        }

        table.cart td.actions input.button {
            width: auto !important;
        }

        #content div.product .woocommerce-tabs,
        div.product .woocommerce-tabs,
        &.woocommerce-page #content div.product .woocommerce-tabs,
        &.woocommerce-page div.product .woocommerce-tabs {
            clear: none;
            float: right;
            width: 48%;
        }

        .upsells.products {
            border-top: 1px solid $grey-60;
            float: left;
            padding-top: 30px;
            width: 100%;
        }

        div.product div.images div.thumbnails {
            display: flex;
            flex-wrap: wrap;
            margin-left: -20px;
            margin-top: 20px;
            padding-top: 0;
        }

        #content div.product div.thumbnails a,
        div.product div.thumbnails a {
            float: none;
            margin-left: 20px;
            margin-right: 0;
            margin-bottom: 20px;
            width: calc(25% - 20px);
        }

        div.product div.images {
            margin-bottom: 40px;
            float: left;
            width: 48%;
        }

        div.product div.summary {
            float: right;
            width: 48%;
        }

        .page-description {
            width: 60%;
        }

        a.continue-shopping {
            width: auto;
        }

        .order_details li {
            float: left;
            margin-right: 2em;
            padding-right: 2em;
            border-right: 1px solid $grey-60;
        }
    }

    .payment_method_mysoft_ocr_payment_gateway,
    .payment_method_signicat_payment_gateway {
        display: none !important;
    }

    // Hide Swedbank Payment options on shop
    .payment_method_payex_psp_swish,
    .payment_method_payex_psp_cc {
        display: none !important;
    }

    // Used for custom form handling in woocommerce account pages
    form > .woocommerce-form-error-message {
        margin-top: 24px !important;
    }

    .woocommerce-error,
    .woocommerce-form-error-message {
        padding: 1em 2em 1em 3.5em !important;
        margin: 0 0 2em !important;
        position: relative;
        background-color: #f7f6f7;
        color: #515151;
        border-top: 3px solid #b81c23;
        list-style: none !important;
        width: auto;
        word-wrap: break-word;
        max-width: 690px;

        &::before {
            content: "\e016";
            color: #b81c23;
            font-family: WooCommerce;
            display: inline-block;
            position: absolute;
            top: 1em;
            left: 1.5em;
            list-style: none !important;
            word-wrap: break-word;
        }

        li {
            list-style: none !important;
            padding-left: 0 !important;
            margin-left: 0 !important;
        }
    }

    .woocommerce-cart & table.cart {

        img {
            width: 60px;
        }

        .cart_item {
            .remove {
                width: 26px;
                height: 26px;
                color: $base-color !important;
                font-weight: normal;
                transition: background $transition, color $transition;

                &:hover {
                    color: $red !important;
                    background: $red-5 !important;
                }
            }
        }
    }

    .woocommerce-page {
        .woocommerce-message {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-direction: row-reverse;
            padding: 15px 10px !important;
            background-color: $grey-20;
            border-top: none;
            margin: 0 !important;

            &::after,
            &::before {
                display: none;
            }
        }

        #content div.product .woocommerce-tabs ul.tabs li.reviews_tab {
            display: none;
        }


        @include media(ML) {
            .woocommerce-message {
                padding: 15px 60px !important;
                background-color: $grey-20;
            }
        }
    }

    /*====================================
    =            Account page            =
    ====================================*/

    .woocommerce-account .woocommerce-MyAccount-content {
        float: none;
        width: auto;
        max-width: 690px;
        margin-bottom: 40px;

        p, a {
            font-size: 1.6rem;
        }

        .order {
            font-size: 1.6rem;
        }
    }

    .woocommerce-account .woocommerce-MyAccount-navigation {
        float: none;
        width: auto;
    }

    .woocommerce-MyAccount-navigation {
        flex: 0 1 auto;
        width: 100%;
        border-top: 1px solid $grey-20;
        margin-bottom: 32px;

        @include media(M) {
            max-width: 339px;
            margin-right: 32px;
        }

        @include media(L) {
            margin-right: 48px;
        }

        @include media(XL) {
            margin-right: 64px;
        }

        .woocommerce-MyAccount-navigation-link {
            position: relative;

            &::before {
                content: '';
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                width: 5px;
                background: $red;
                opacity: 0;
                transition: opacity $transition;
            }

            &.is-active {
                background-color: $red-5;

                &::before {
                    opacity: 1;
                }
            }
        }

        a {
            display: block;
            border-bottom: 1px solid $grey-20;
            padding: 20px 24px 19px;
            font-weight: $bold;
            font-size: 1.6rem;
            color: $base-color;
            transition: all 0.25s ease;

            &:hover {
                background-color: $red-5;
            }
        }
    }

    .woocommerce-MyAccount-content {
        flex: 1 1 auto;
        max-width: map-get($maxwidths, content);

        .addresses.col2-set {
            display: flex;
            flex-direction: column;

            .col-1,
            .col-2 {
                width: 100%;
                margin: 32px 0;
            }

            .edit {
                position: relative;
                color: $link-color;
                text-decoration: underline;
                transition: color $transition;

                &:hover {
                    color: $link-hover-color;
                    text-decoration: none;
                }

                &::after {
                    content: '';
                    display: inline-block;
                    position: relative;
                    top: 1px;
                    width: 15px;
                    height: 15px;
                    margin-left: 5px;
                    background: url('#{$assetsPath}/img/external--red.svg') no-repeat center center;
                    background-size: contain;
                }
            }

            address {
                @include textstyle(body-small);

                color: $base-color;
                margin: 8px 0;
                border-width: 1px;
            }
        }

        .woocommerce-orders-table__cell.woocommerce-orders-table__cell-order-actions {
            .button {
                padding: 6px 16px;
                min-height: 0;
                font-size: 1.4rem;
            }
        }
    }

    .flexbox .woocommerce-Address-title {
        display: flex;
        align-items: baseline;

        .edit {
            margin-left: 20px;
            font-size: 1.6rem;
        }
    }

    // Hide order status since HRF handle orders manually
    .woocommerce-account .order-status {
        display: none;
    }

    @include media(ML) {
        .woocommerce-account .woocommerce-MyAccount-content {
            p, a {
                font-size: 2rem;
            }

            .order,
            .order a,
            .order-item a {
                font-size: 1.6rem;
            }
        }
    }

    /*=====  End of Account page  ======*/
}
